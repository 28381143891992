<!-- 猜你喜欢 第二种-->
<template>
  <div class="cnt" v-if='likesData&&likesData.length>0'>
    <h6 class="h6">猜你喜欢<span>What interests you</span></h6>
<!--     <el-row :gutter='12'>
      <el-col :span="6" v-for='item,index in likesData' :key='index'>
        <div class="box" @click='links(item)'>
          <div class="imgs">
            <img :src="item.src">
          </div>
          <h6 :title='item.title'>{{item.title}}</h6>
          <p class="price">&yen;{{item.price}}</p>
          <p class="people">{{item.people}}人购买</p>
        </div>
      </el-col>
    </el-row> -->
    <el-carousel  :autoplay="false" arrow="hover" :loop='false' indicator-position='none' :height='height'>
      <el-carousel-item v-for="items,indexs in likesData" :key="indexs">
          <el-row :gutter='12'>
            <el-col :span="6" v-for='item,index in items.arr' :key='index'>
              <div class="box" @click='links(item)' :class='{type1:type==0}'>
                <div class="imgs">
                  <img :src="item.src">
                </div>
                <h6 :title='item.title'>{{item.title}}</h6>
                <p class="price">&yen;{{item.price}}</p>
                <p class="people">{{item.people}}人购买</p>
              </div>
            </el-col>
          </el-row>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: 'likes1',
  props: {
    height: String,
    carousels:Array,
    type:null
  },
  data(){
    return {
      likesData:[
        // {src:require('../../assets/img/likes/img.jpg'),title:'荷包纸巾纸巾定制纸巾定制纸巾定制',price:380,people:937},
        // {src:require('../../assets/img/likes/img1.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img2.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img3.jpg'),title:'荷包纸巾',price:380,people:937},
        // {src:require('../../assets/img/likes/img4.jpg'),title:'荷包纸巾',price:380,people:937},
      ]
    }
  },
  methods:{
    links(i){
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:i.Id,idcx:'',detailType:1}})
        var id = i.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 请求猜你喜欢数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URLS+'Home/GetProductUserLikeList',this.keys())
        .then(res => {
            if(res.data.Success){
              // var _data = [];
              // for(let i in res.data.Data){
              //   var _obj = {};
              //   _obj.src = this._src + res.data.Data[i].ImagePath
              //   _obj.title = res.data.Data[i].TypeName
              //   _obj.price = res.data.Data[i].Price
              //   _obj.people = res.data.Data[i].SalesVolume
              //   _obj.Id = res.data.Data[i].Id
              //   _data.push(_obj)
              // }
              var _data = [];
              var _obj = {};
                  _obj.arr = [];
              if(res.data.Data.length<5){
                for(let i in res.data.Data){
                  _obj.arr.push({
                    src:this._src+res.data.Data[i].ImagePath,
                    title:res.data.Data[i].AttachTypeName,
                    price:res.data.Data[i].Price,
                    people:res.data.Data[i].SalesVolume,
                    Id:res.data.Data[i].Id,
                    // oldP:res.data.Data[i].OriginalPrice,
                    // hot:res.data.Data[i].IsHot
                  });
                }
                _data.push(_obj)
              }else{
                for(let i in res.data.Data){
                  _obj.arr.push({
                    src:this._src+res.data.Data[i].ImagePath,
                    title:res.data.Data[i].AttachTypeName,
                    price:res.data.Data[i].Price,
                    people:res.data.Data[i].SalesVolume,
                    Id:res.data.Data[i].Id,
                    // oldP:res.data.Data[i].OriginalPrice,
                    // hot:res.data.Data[i].IsHot
                  });
                  if(i>0&&(parseInt(i)+1)%5==0){
                    _data.push(_obj)
                    _obj = {};
                    _obj.arr = [];
                  }
                }
                if(res.data.Data.length%5!=0){
                  _data.push(_obj)
                }
              }
              this.$set(this,'likesData',_data)
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.cnt {
  .h6 {
    text-align: center;height: 70px;font-size: 22px;color:#343434;padding-top:30px;
    span {
      color:#ccc;font-size: 12px;text-align: center;display: block;font-weight: normal;margin-top:7px;
    }
  }
  .el-row {
    margin-bottom:12px;
    .el-col-6 {
      width: 20%;
      .box {
        background: #fff;height: 280px;border-radius: 10px;
        .imgs {
          height: 200px;display: flex;justify-content: center;align-items: center;border:1px solid #e4e4e4;border-bottom:0px;border-top-left-radius: 10px;border-top-right-radius: 10px;
          img {
            display: block;max-width: 80%;max-height: 80%;
          }
        }
        h6 {
          text-align: center;padding:0 20px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;font-size: 15px;background: #e5e5e5;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;height: 36px;line-height: 36px;
        }
        .price {
          text-align: center;font-size: 16px;color:#d41e44;margin-top:5px;
        }
        .people {
          text-align:center;font-size: 12px;color:#828282;
        }
        &:hover {
          cursor: pointer;opacity: 0.8;
          h6 {
            color:#ca141d;
          }
        }
      }
      .type1{
        background: none;
        .imgs {
          background: #fff;
        }
      }
    }
    &:last-child {
      margin-bottom:0px;
    }
  }
  // /deep/.el-carousel {
  //   width: 100%;
  // }
  /deep/.el-carousel__arrow {
    border-radius: 0px;width: 25px;height: 56px;background-color:rgba(0,0,0,0.2);top:115px;
    // .el-icon-arrow-left:before  {
    //   color:#908c8d;font-weight: bold;
    // }
    // .el-icon-arrow-right:before {
    //   color:#908c8d;font-weight: bold;
    // }
  }
  /deep/.el-carousel__indicators {
    display: none;
  }
}
</style>
