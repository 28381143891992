<template>
  <div class="classlistitem">
    <el-row :gutter='15'>
      <el-col :span="4" v-for='item,index in searchList' @click.native='links(item)' :key='index'>
        <div class="img">
          <img :src="item.ImagePath">
        </div>
        <div class="price">
          <span>&yen;</span>{{item.Price}}
        </div>
        <div class="title" :title='item.AttachTypeName'>
          {{item.AttachTypeName}}
        </div>
        <div class="other">
          <p><span>{{item.evaluateNum}}</span>条评论</p>
          <button @click.stop='joinCar(item)' title='加入购物车'><svg  ><use xlink:href="#icongouwucheman"></use></svg></button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {setCarlist} from '../../assets/js/common.js' //公用js
export default {
  name: 'classlistitem',
  data(){
    return {
      productData:null
    }
  },
  props: {
    msg: String,
    searchList:null
  },
  methods:{
    links(item){
      // 跳转详情
      this._router({then:()=>{
        var id = item.Id + '.html'
        this._open({path:'/predetail',query:{idcx:'',id:id,}})
      }})
    },
    joinCar(item){
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      if(!item.Price||item.Price==0){
       this.$MessageBox.alert('产品没有报价，无法加入购物车！',{type:'error'}).catch(() => {
                  // console.log('再逛逛')
                });
       return;
      }
      if(!item.Stock||item.Stock==0){
        this.$MessageBox.alert('产品库存不足，无法加入购物车！',{type:'error'}).catch(() => {
                  // console.log('再逛逛')
                });
        return;
      }
      // 加入购物车
      this.productData = {
        buynumber:1,
        carId:item.Productid,
        type:1,
        idcx:''
      }
      if(CustomerID){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Shop/BatchAddShoppingCart?productIds='+this.productData.carId+'&nums='+this.productData.buynumber+'&SellingTypes='+this._spf(this.$route.query.detailType)+'&dynamicIds='+this._spf(this.$route.query.idcx),this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
                //   this.$store.dispatch("cmtSetUser",null);//赋值
                //   this._usermet({method:'del'});//存本地
                //   this._token({method:'del'});//存本地
                //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
                // }
                this.$store.dispatch('cmtBuycarum',parseInt(res.data.Data.shopCount))
                this.$MessageBox.confirm('商品成功加入购物车', {
                  confirmButtonText: '去结算',
                  cancelButtonText: '再逛逛',
                  type: 'success',
                  customClass:'joinCar'
                }).then(() => {
                  this._router({then:()=>{
                    this.$router.push({path:'/buycar.html'})
                  }})
                  // console.log('去结算')
                }).catch(() => {
                  // console.log('再逛逛')
                });
                // end
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
        }})
      }else{
        // this.productData存入本地进入购物车获取本地加用户信息中的数据
        setCarlist({method:'set',data:this.productData})
        var _carlist = setCarlist({method:'get'})
        if(_carlist){
          this.$store.dispatch('cmtBuycarum',parseInt(_carlist.length))
        }
        // else{
        //   this.$store.dispatch('cmtBuycarum',0)
        // }
        // end
        // 加入购物车
        this.$MessageBox.confirm('商品成功加入购物车', {
          confirmButtonText: '去结算',
          cancelButtonText: '再逛逛',
          type: 'success',
          customClass:'joinCar'
        }).then(() => {
          this._router({then:()=>{
            this.$router.push({path:'/buycar.html'})
          }})
          // console.log('去结算')
        }).catch(() => {
          console.log('再逛逛')
        });
        // end
      }
    },
  },
  mounted(){
  }
}
</script>
<style lang="scss">
.joinCar {
  .el-message-box__btns {
    button {
      &:hover {
        color:#333;background: rgba(0,0,0,0.08);border:1px solid #ccc;
      }
    }
    .el-button--primary {
      background: #f54d70;border:1px solid #f54d70;
      &:hover {
        color:#fff;background: #f54d70;opacity: 0.8;border:1px solid #f54d70;
      }
    }
  }
}
</style>
<style  lang="scss" scoped>
.el-row {
  .el-col-4 {
    width: 20%;margin-bottom:15px;cursor: pointer;
    .img {
      width: 100%;height: 190px;display:flex;justify-content: center;align-items: center;background: #fff;border-top-right-radius: 10px;border-top-left-radius: 10px;
      img {
        display: block;width: 80%;height: 80%;
      }
    }
    .price {
      height: 35px;line-height: 35px;color:#d20c2e;font-weight: bold;font-size: 20px;padding:0 10px;background: #fff;
      span {
        font-size: 14px;margin-right: 5px;
      }
    }
    .title {
      padding:0px 10px;font-size: 12px;background: #fff;height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
      -webkit-line-clamp: 2; /* 控制最多显示几行 */
      -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    }
    .other {
      background: #fff;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;padding-top:5px;padding-bottom:10px;
      p {
        float:left;margin-left:10px;font-size: 12px;color:#ccc;
        span {
          color:#5ebdfa;font-size:12px;
        }
      }
      button {
        float:right;margin-right:5px;fill:#f7676b;width: 15px;height: 15px;cursor: pointer;background: none;margin-top:-5px;
        svg {
          float:right;margin-right:10px;fill:#f7676b;width:20px;height: 20px;cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &:after {
        content: '';display: block;clear:both;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

</style>
