<!-- 其他商家 -->
<template>
  <div class="otherBrand"  v-show='initTem'>
    <h1>TA们都选择微微定</h1>
    <div class="imgs" name='imgs' v-show='waitImg'>
      <a :href="item.LinkUrl?item.LinkUrl:'javascript:;'" target="_blank" v-for='item,index in imgData' :title='item.tip?item.tip:""' :key='index'><img :src="item.src"  ></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'otherBrand',
  data(){
    return {
      imgData:[
        // {src:require('../../assets/img/otherBrand/1.jpg')},
        // {src:require('../../assets/img/otherBrand/2.jpg')},
        // {src:require('../../assets/img/otherBrand/3.jpg')},
        // {src:require('../../assets/img/otherBrand/4.jpg')},
        // {src:require('../../assets/img/otherBrand/5.jpg')},
        // {src:require('../../assets/img/otherBrand/6.jpg')},
        // {src:require('../../assets/img/otherBrand/7.jpg')},
        // {src:require('../../assets/img/otherBrand/8.jpg')},
        // {src:require('../../assets/img/otherBrand/9.jpg')},
        // {src:require('../../assets/img/otherBrand/10.jpg')},
      ],
      waitImg:false,
      initTem:false,
    }
  },
  props: {
    msg: String,
    type:null
  },
  methods:{
  },
  mounted(){
    this.$nextTick(() => {
     var _this = this
      // 获取合作方
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URLS+'Home/GetAllPartners',this.keys())
        .then(res => {
            if(res.data.Success){
              var _data= [];
              for(let i in res.data.Data.List){
                var _obj = {};
                _obj.src = this._src + res.data.Data.List[i].PartnersPath
                _obj.tip = res.data.Data.List[i].PartnersName
                _obj.LinkUrl = res.data.Data.List[i].LinkUrl
                _data.push(_obj)
              }
              this.$set(this,'imgData',_data)
              // 自动滚动展示
              setTimeout(()=>{
                $('div[name=imgs]').find('img').each(function(i,v){
                  if($(v)[0].height==380){
                    $(v)[0].style.display='none'
                    $(v).parent()[0].style.display='none'
                    $(v).parent().remove()
                  }
                  if($(v)[0].height==91){
                    _this.initTem = true
                    // console.log(v)
                    // $(v).parent().attr('name','moreastyle')
                  }
                })
                _this.waitImg = true
              },1000)
              // end
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      }})
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.otherBrand {
  h1 {
    text-align: center;font-size: 22px;color:#313131;
  }
  .imgs {
    display: block;zoom:1;margin-top:25px;
    a {
      float:left;display: block;border:1px solid #ccc;margin-right: 15px;margin-bottom:15px;
      img {
        display: block;
      }
      &:nth-child(5n+5){
        margin-right:0px;
      }
    }
    // a[name=moreastyle] {
    //   &:nth-child(5n+5){
    //     margin-right:0px;
    //   }
    // }
    &:after {
      display: block;content: '';clear:both;
    }
  }
}
</style>
