<!-- 商品-分类列表 -->
<template>
  <div class="classlist">
    <crumbs class='crumbs' :type='1' :location='location' @exportallresult='exportallresult' @exportCls='exportCls'></crumbs>
    <classallmenu @exportShTit='exportShTit' v-if='typelist&&typelist.length>0' class='classallmenu' :typelist='typelist'></classallmenu>
    <searchparam @exportStr='exportStr' @exportEnd='exportEnd' @exportChoice='exportChoice' class='searchparam' ></searchparam>
    <div v-show='loadings' class="loading">
      <svg><use xlink:href="#iconloading"></use></svg>
    </div>
    <classlistitem class='searchparam' v-if='searchList' :searchList='searchList.List'></classlistitem>
    <div class='page'>
      <el-pagination
        v-if='searchList&&searchList.List.length>0'
        @current-change='pages'
        :page-size='50'
        background
        layout="prev, pager, next"
        :current-page.sync='pageNum'
        :total="searchList.TotalCount">
      </el-pagination>
    </div>
    <div class="nofound" v-show='shownone'>
      <p>抱歉没有找到相关的商品</p>
    </div>
    <div class="coms">
      <likes1 type='0'class='likes1'></likes1>
      <otherBrand  class='otherBrand'></otherBrand>
    </div>
  </div>
</template>
<script>
import classallmenu from '@/components/classallmenu/classallmenu.vue';//分类按钮
import searchparam from '@/components/searchparam/searchparam.vue';//其他查询参数
import classlistitem from '@/components/classlistitem/classlistitem.vue';//分类产品列表组件
import likes1 from '@/components/likes1/likes1.vue';//猜你喜欢 第二种
import otherBrand from '@/components/otherBrand/otherBrand.vue';//其他商家
import crumbs from '@/components/crumbs/crumbs.vue';//面包屑
export default {
  name: 'classlist',
  metaInfo() {
    return {
      // title:this.htmltitle,
      title:JSON.parse(window.sessionStorage.getItem('localseo'))&&JSON.parse(window.sessionStorage.getItem('localseo')).SeoTitle?JSON.parse(window.sessionStorage.getItem('localseo')).SeoTitle:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoTitle,
      meta: [
        {
        name: "description",
          content:JSON.parse(window.sessionStorage.getItem('localseo'))&&JSON.parse(window.sessionStorage.getItem('localseo')).SeoDescrition?JSON.parse(window.sessionStorage.getItem('localseo')).SeoDescrition:JSON.parse(window.sessionStorage.getItem('classlistseo')).SeoDescrition
      },
      {
        name: "Keywords",
          content:JSON.parse(window.sessionStorage.getItem('localseo'))&&JSON.parse(window.sessionStorage.getItem('localseo')).SeoKey?JSON.parse(window.sessionStorage.getItem('localseo')).SeoKey:JSON.parse(window.sessionStorage.getItem('classlistseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'loading...',
      typelist:null,
      searchList:null,
      location:[],//地址
      pageNum:null,//查询参数
      shownone:false,//显示无数据
      loadings:false,//加载动画
    }
  },
  components: {
    classallmenu,
    searchparam,
    classlistitem,
    likes1,
    otherBrand,
    crumbs
  },
  props: {
    msg: String
  },
  methods:{
    exportCls(v){
      // 面包屑选中分类
      this.exportShTit(v)
    },
    exportallresult(){
      // 全部结果-面包屑分类
      // console.log(1)
      this.location=[];
      this.$store.dispatch('cmtsearchTxt','')
      this.$store.dispatch('cmtsearchClick',this.$store.state.searchClick+1)
      this.$router.push({path:this.$route.path,query:{keys:'.html'}})
      window.sessionStorage.removeItem('searchQuery')
      // this.pageNum =1;
      this.$set(this,'pageNum',1)
      // 点击查询也是两种接口，有sess和没sess,同时需要缓存session查询参数
      if(this.$store.state.searchTxt){
        var _sctitle =this.$store.state.searchTxt+ '-商品搜索-微微定';//搜索头
      }else{
        var _sctitle = '商品搜索-微微定';//搜索头
      }
      // this._settitle({title:_sctitle})
      this.htmltitle = _sctitle
      var _searchQueryObj = {
        key:this.$store.state.searchTxt,
        orderBy:0,
        OrderT:1,
        parentId:'',
        startPrice:0,
        endPrice:0,
        index:1
      }
      this.getDatas({key:_searchQueryObj.key,index:_searchQueryObj.index,size:50,type:_searchQueryObj.orderBy,value:_searchQueryObj.OrderT,parentId:_searchQueryObj.parentId,startPrice:_searchQueryObj.startPrice,endPrice:_searchQueryObj.endPrice},_searchQueryObj)
    },
    pages(v){
      // 选择页码
      // console.log(v)
      // return;
      var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
      if(_searchQueryObj){
        _searchQueryObj.index  = v
      }else{
          _searchQueryObj = {
          key:this.$store.state.searchTxt,
          orderBy:0,
          OrderT:1,
          parentId:'',
          startPrice:0,
          endPrice:0,
          index:1
        }
      }
      this.getDatas({key:_searchQueryObj.key,index:_searchQueryObj.index,size:50,type:_searchQueryObj.orderBy,value:_searchQueryObj.OrderT,parentId:_searchQueryObj.parentId,startPrice:_searchQueryObj.startPrice,endPrice:_searchQueryObj.endPrice},_searchQueryObj)
    },
    exportStr(v){
      // 开始价格
      var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
      if(_searchQueryObj){
        _searchQueryObj.startPrice  = v
        _searchQueryObj.index  = 1
        this.$set(this,'pageNum',1)
      }else{
          _searchQueryObj = {
          key:this.$store.state.searchTxt,
          orderBy:0,
          OrderT:1,
          parentId:v.Id,
          startPrice:0,
          endPrice:0,
          index:1
        }
      }
      this.getDatas({key:_searchQueryObj.key,index:_searchQueryObj.index,size:50,type:_searchQueryObj.orderBy,value:_searchQueryObj.OrderT,parentId:_searchQueryObj.parentId,startPrice:_searchQueryObj.startPrice,endPrice:_searchQueryObj.endPrice},_searchQueryObj)
    },
    exportEnd(v){
      // 结束价格
      var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
      if(_searchQueryObj){
        _searchQueryObj.endPrice  = v
        _searchQueryObj.index  = 1
        this.$set(this,'pageNum',1)
      }else{
          _searchQueryObj = {
          key:this.$store.state.searchTxt,
          orderBy:0,
          OrderT:1,
          parentId:v.Id,
          startPrice:0,
          endPrice:0,
          index:1
        }
      }
      this.getDatas({key:_searchQueryObj.key,index:_searchQueryObj.index,size:50,type:_searchQueryObj.orderBy,value:_searchQueryObj.OrderT,parentId:_searchQueryObj.parentId,startPrice:_searchQueryObj.startPrice,endPrice:_searchQueryObj.endPrice},_searchQueryObj)
    },
    exportShTit(v){
      // 分类id
      this.location=[];
      this.location.push(v)
      var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
      if(_searchQueryObj){
        _searchQueryObj.parentId  = v.Id
      }else{
          _searchQueryObj = {
          key:this.$store.state.searchTxt,
          orderBy:0,
          OrderT:1,
          parentId:v.Id,
          startPrice:0,
          endPrice:0,
          index:1
        }
      }
      this.getDatas({key:_searchQueryObj.key,index:_searchQueryObj.index,size:50,type:_searchQueryObj.orderBy,value:_searchQueryObj.OrderT,parentId:_searchQueryObj.parentId,startPrice:_searchQueryObj.startPrice,endPrice:_searchQueryObj.endPrice},_searchQueryObj)
    },
    exportChoice(v){
      // 分类栏目选择
      var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
      if(_searchQueryObj){
        _searchQueryObj.orderBy = v.type
        _searchQueryObj.OrderT  = v.value
        _searchQueryObj.index  = 1
        this.$set(this,'pageNum',1)
      }else{
          _searchQueryObj = {
          key:this.$store.state.searchTxt,
          orderBy:v.type,
          OrderT:v.value,
          parentId:'',
          startPrice:0,
          endPrice:0,
          index:1
        }
      }
      this.getDatas({key:_searchQueryObj.key,index:_searchQueryObj.index,size:50,type:_searchQueryObj.orderBy,value:_searchQueryObj.OrderT,parentId:_searchQueryObj.parentId,startPrice:_searchQueryObj.startPrice,endPrice:_searchQueryObj.endPrice},_searchQueryObj)
    },
    getData(param){
      // 无session查询参数接口
      this.loadings = true;
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Product/ProductSearchPage?key='+param.key+'&parentId='+(param.parentId?param.parentId:'')+'&pageIndex='+param.index+'&pageSize='+param.size,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.loadings = false;
              if(!res.data.Data.searchList.List||res.data.Data.searchList.List.length<1){
                this.shownone = true
              }else {
                this.shownone = false
              }
              console.log(res.data)
              if(param.fn){
                param.fn(res)
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
    getDatas(param,_searchQueryObj){
      // 有session查询参数接口
      this.loadings = true;
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      // if(_searchQueryObj){
      //   window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
      // }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'Product/ProductSearchPage?key='+param.key+'&pageIndex='+param.index+'&pageSize='+param.size+'&orderBy='+param.type+'&OrderT='+param.value+'&parentId='+param.parentId+'&startPrice='+param.startPrice+'&endPrice='+param.endPrice+'&CustomerId='+CustomerID,this.keys())
        .then(res => {
            if(res.data.Success){
              this.loadings = false
              if(!res.data.Data.searchList.List||res.data.Data.searchList.List.length<1){
                this.shownone = true
              }else {
                this.shownone = false
              }
              if(res.data.Data){
                this.typelist = res.data.Data.typelist
                for(let i in res.data.Data.searchList.List){
                  res.data.Data.searchList.List[i].ImagePath = this._src + res.data.Data.searchList.List[i].ImagePath
                }
                this.$set(this,'searchList',res.data.Data.searchList)
              }
              var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
              if(_searchQueryObj){
                if(_searchQueryObj.parentId){
                  for(let i in this.typelist){
                    if(this.typelist[i].Id==_searchQueryObj.parentId){
                      this.location = []
                      this.location.push(this.typelist[i])
                    }
                  }
                }else{
                  this.choiceIdx=null
                }
              }else{
                 this.choiceIdx=null
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
    localReset(){
      // if(this._spf(this.$route.query.keys)){
      //   this.$store.dispatch('cmtsearchClick',this.$store.state.searchClick+1)
      //   this.$store.dispatch('cmtsearchTxt',this._spf(this.$route.query.keys))
      // }
      this.$nextTick(()=>{
        if(this._spf(this.$route.query.keys)){
            this.$store.dispatch('cmtsearchClick',this.$store.state.searchClick+1)
            this.$store.dispatch('cmtsearchTxt',this._spf(this.$route.query.keys))
            // typelist
            // this.getData({key:'',index:1,size:50,fn:(res)=>{
            //   if(res.data.Data){
            //     var _typelist = res.data.Data.typelist
            //     var _typeId = this._spf(this.$route.query.keys)
            //     var flag = false
            //     for(let i in _typelist){
            //       if(_typelist[i].Id === _typeId){
            //         flag = true
            //         this.$store.dispatch('cmtsearchTxt',_typelist[i].Name)
            //       }
            //     }
            //     if(!flag){
            //     }
            //   }
            // }})
        }
        if(window.sessionStorage.getItem('searchQuery')){
          var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
          if(_searchQueryObj.key){
            var _sctitle =_searchQueryObj.key+ '-商品搜索-微微定';//搜索头
          }else{
            if(this._spf(this.$route.query.keys)){
              var _sctitle =this._spf(this.$route.query.keys)+ '-商品搜索-微微定';//搜索头
              _searchQueryObj.key = this._spf(this.$route.query.keys)
              window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
            }else{
              var _sctitle = '商品搜索-微微定';//搜索头
            }
          }
          // this._settitle({title:_sctitle})
          this.htmltitle = _sctitle
          // window.sessionStorage.setItem('searchQuery',JSON.stringify(this.$route.query))
          this.$store.dispatch('cmtsearchTxt',_searchQueryObj.key)
          this.pageNum = _searchQueryObj.index;
          this.setSeo(_searchQueryObj.key)
          this.getDatas({key:_searchQueryObj.key,index:_searchQueryObj.index,size:50,type:_searchQueryObj.orderBy,value:_searchQueryObj.OrderT,parentId:_searchQueryObj.parentId,startPrice:_searchQueryObj.startPrice,endPrice:_searchQueryObj.endPrice})
        }else{
          var _searchQueryObj = {
            key:this.$store.state.searchTxt,
            orderBy:0,
            OrderT:1,
            parentId:'',
            startPrice:0,
            endPrice:0,
            index:1
          }
          _searchQueryObj.key = this._spf(this.$route.query.keys)?this._spf(this.$route.query.keys):''
          window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
          this.pageNum = _searchQueryObj.index;
          if(this._spf(this.$route.query.keys)){
            var _sctitle =this._spf(this.$route.query.keys)+ '-商品搜索-微微定';//搜索头
            _searchQueryObj.key = this._spf(this.$route.query.keys)
            window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
          }else{
            var _sctitle = '商品搜索-微微定';//搜索头
          }
          // var _sctitle = '商品搜索-微微定';//搜索头
          this.htmltitle = _sctitle
          this.getData({key:_searchQueryObj.key,parentId:_searchQueryObj.parentId,index:1,size:50,fn:(res)=>{
            if(res.data.Data){
              this.typelist = res.data.Data.typelist
              this.searchList = res.data.Data.searchList
              for(let i in this.searchList.List){
                this.searchList.List[i].ImagePath = this._src + this.searchList.List[i].ImagePath
              }
            }
          }})
        }
      })
    },
    setSeo(v){
      // console.log(v)
      if(v){
        this.axios.post(process.env.VUE_APP_URLS+'Home/GetSearchSEO?SearchKey='+v,this.keys())
        .then(res => {
          if(res.data.Data){
            var _obj = {
              SeoTitle:res.data.Data.SeoTitle,
              SeoKey:res.data.Data.SeoKey,
              SeoDescrition:res.data.Data.SeoDescrition
            }
            window.sessionStorage.setItem('classlistseo',JSON.stringify(_obj))
            window.sessionStorage.setItem('localseo',window.sessionStorage.getItem('classlistseo'))
          }
        }, res => {
        })
      }else{
        this.axios.post(process.env.VUE_APP_URLS+'Home/GetSearchSEO?SearchKey='+'',this.keys())
        .then(res => {
          if(res.data.Data){
            var _obj = {
              SeoTitle:res.data.Data.SeoTitle,
              SeoKey:res.data.Data.SeoKey,
              SeoDescrition:res.data.Data.SeoDescrition
            }
            window.sessionStorage.setItem('classlistseo',JSON.stringify(_obj))
            window.sessionStorage.setItem('localseo',window.sessionStorage.getItem('classlistseo'))
          }
        })
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
    '$store.state.searchClick'(n,o){
      // console.log(n,o)
      if(this.$route.path.indexOf('/classlist')!=-1){
        this.location=[];
        // 点击查询也是两种接口，有sess和没sess,同时需要缓存session查询参数
        if(this.$store.state.searchTxt){
          var _sctitle =this.$store.state.searchTxt+ '-商品搜索-微微定';//搜索头
        }else{
          var _sctitle = '商品搜索-微微定';//搜索头
        }
        // this._settitle({title:_sctitle})
        this.htmltitle = _sctitle
        var _searchQueryObj = {
          key:this.$store.state.searchTxt,
          orderBy:0,
          OrderT:1,
          parentId:'',
          startPrice:0,
          endPrice:0,
          index:1
        }
        this.$set(this,'pageNum',1)
        _searchQueryObj.parentId = this._spf(this.$route.query.parentId)?this._spf(this.$route.query.parentId):''
        this.setSeo(_searchQueryObj.key)
        this.getDatas({key:_searchQueryObj.key,index:_searchQueryObj.index,size:50,type:_searchQueryObj.orderBy,value:_searchQueryObj.OrderT,parentId:_searchQueryObj.parentId,startPrice:_searchQueryObj.startPrice,endPrice:_searchQueryObj.endPrice},_searchQueryObj)
      }
    },
  },
  destroyed(){
    // 组件销毁清空定时变量
    // window.sessionStorage.removeItem('searchQuery')
  },
}
</script>
<style  lang="scss" scoped>
.loading {
   width: 1200px;text-align: center;position: relative;z-index: 9;margin:0 auto;height: 0px;
  svg {
    width: 30px;height: 30px;animation:turn 1s linear infinite;position: absolute;top:20px;
  }
  @keyframes turn{
    0%{-webkit-transform:rotate(0deg);}
    25%{-webkit-transform:rotate(90deg);}
    50%{-webkit-transform:rotate(180deg);}
    75%{-webkit-transform:rotate(270deg);}
    100%{-webkit-transform:rotate(360deg);}
  }
}
.crumbs {
  margin-bottom:10px;
}
.classallmenu {
  width: 1200px;margin:0 auto;
}
.searchparam {
  width: 1200px;margin:0 auto;margin-top:50px;
}
.searchparam {
  width: 1200px;margin:0 auto;margin-top:20px;
}
.coms {
  width: 1200px;margin:0 auto;margin-top:30px;
  .likes1 {
    padding-bottom:50px;
  }
  .otherBrand {
    padding-bottom:20px;
  }
}
.page {
  width: 1200px;margin:0 auto;display: flex;justify-content: center;margin-top:25px;margin-bottom:20px;
  /deep/.el-pagination {
    .el-icon-more {
      display: none;
    }
    .number {
      background: #fff;
      &:hover {
        color:#f7676b!important;
      }
    }
    .btn-prev {
      background: #fff;
      &:hover {
        // background: #f7676b;
        color:#f7676b;
      }
    }
    .btn-next {
      background: #fff;
      &:hover {
        // background: #f7676b;
        color:#f7676b;
      }
    }
    .el-pager {
      .active {
        background-color:#f7676b!important;
      }
    }
  }
}
.nofound {
  p {
    text-align: center;
  }
}
</style>
