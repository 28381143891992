<template>
  <div class="classallmenu">
    <div class="lt">
      分类：
    </div>
    <div class="rt">
      <div class="group-item">
        <button @click='menus(item,index)' :class='{choice:choiceIdx==index}' v-for='item,index in typelist'>{{item.Name}}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'classallmenu',
  data(){
    return {
      choiceIdx:null
    }
  },
  props: {
    msg: String,
    typelist:null,
  },
  methods:{
    menus(v,idx){
      // 点击分类
      // 修改查询参数
      // console.log(v)
      var query=JSON.parse(JSON.stringify(this.$route.query))
      query.keys = v.Name
      query.parentId = v.Id + '.html'
      this.$router.push({path:this.$route.path,query:query})
      // 修改查询参数end
      this.$emit('exportShTit',v)
      this.choiceIdx = idx
    },
    goBack(){
      // 监听浏览器前进后退，读取查询参数重置数据
      var queryId = this.$route.query.keys.substring(0,this.$route.query.keys.indexOf('.html'))
      // console.log(this.typelist)
      for(let i in this.typelist){
        if(this.typelist[i].Id === queryId){
          this.$emit('exportShTit',this.typelist[i])
          this.choiceIdx = i
        }
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 初始化默认选中
      var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
      if(_searchQueryObj){
        if(_searchQueryObj.parentId){
          for(let i in this.typelist){
            if(this.typelist[i].Id==_searchQueryObj.parentId){
              this.choiceIdx=i
            }
          }
        }else{
          this.choiceIdx=null
        }
      }else{
         this.choiceIdx=null
      }
      if (window.history && window.history.pushState) {
        //监听浏览器后退事件
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.goBack, false)
      }
    })
  },
  watch:{
    '$store.state.searchClick'(n,o){
      // console.log(n,o)
      this.choiceIdx=null
    },
  },
  destroyed(){
    // 组件销毁清空定时变量
    // window.sessionStorage.removeItem('searchQuery')
    window.removeEventListener('popstate', this.goBack, false)
  }
}
</script>
<style  lang="scss" scoped>
.classallmenu {
  zoom:1;
  .lt {
    font-size: 14px;color:#9e9e9e;width: 42px;float:left;color:#333;
  }
  .rt {
    float:left;width: 1158px;margin-top:-5px;
    button {
      background: #fff;border-radius: 30px;padding:5px 15px;font-size: 14px;color:#4e514e;margin-left:10px;margin-bottom:10px;
      &:hover {
        background: #f88288;color:#fff;
      }
    }
    .choice {
      background: #f88288;color:#fff;
    }
  }
  &:after {
    display: block;content: '';clear:both;
  }
}
</style>
