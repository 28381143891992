<template>
  <div class="searchparam">
    <el-tabs v-model="activeName" @tab-click='choice' >
      <el-tab-pane label="综合" name="choice" >
      </el-tab-pane>
      <el-tab-pane  name="choice1" >
        <template slot="label" >
          销量
          <svg class="arrows" :class='{light:activeName=="choice1"&&initLight}'><use xlink:href="#iconpaixushang"></use></svg>
          <svg class="arrows" :class='{light:activeName=="choice1"&&!initLight}'><use xlink:href="#iconpaixuxia"></use></svg>
        </template>
      </el-tab-pane>
      <el-tab-pane name="choice2" >
        <template slot="label" >
          评论数
          <svg class="arrows" :class='{light:activeName=="choice2"&&initLight}'><use xlink:href="#iconpaixushang"></use></svg>
          <svg class="arrows" :class='{light:activeName=="choice2"&&!initLight}'><use xlink:href="#iconpaixuxia"></use></svg>
        </template>
      </el-tab-pane>
      <el-tab-pane  name="choice3">
        <template slot="label" >
          价格
          <svg class="arrows" :class='{light:activeName=="choice3"&&initLight}'><use xlink:href="#iconpaixushang"></use></svg>
          <svg class="arrows" :class='{light:activeName=="choice3"&&!initLight}'><use xlink:href="#iconpaixuxia"></use></svg>
        </template>
      </el-tab-pane>
<!--       <el-tab-pane  name="choice4">
        <template slot="label">
          价格区间：
          <p><span>&yen;</span><input type="text"></p><p><span>&yen;</span><input type="text"></p>
        </template>
      </el-tab-pane> -->
    </el-tabs>
    <div class="priceNumber">
      价格区间：
      <p><span>&yen;</span><input v-model='endPrice' type="text"></p>
      <p>-</p>
      <p><span>&yen;</span><input v-model='startPrice' type="text"></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'searchparam',
  data(){
    return {
      activeName:"choice",
      activeNamemo:'choice',
      initLight:true,
      startPrice:'',//价格区间
      endPrice:'',//价格区间
      t:0,//时间对象
      t1:0,//时间对象
    }
  },
  props: {
    msg: String,
  },
  methods:{
    choice(v){
      // console.log(this.activeName,this.activeNamemo)
      if(this.activeName!=this.activeNamemo){
        this.initLight = true;
      }else{
        this.initLight=!this.initLight
      }
      // console.log(this.initLight)
      var _type = 0,_value=0;
      switch(this.activeName) {
        case 'choice':
          _type=0
          break;
        case 'choice1':
          _type=1
          break;
        case 'choice2':
          _type=2
          break;
        case 'choice3':
          _type=3
          break;
      }
      if(this.initLight){
        _value=1
      }else{
        _value=0
      }
      this.$emit('exportChoice',{type:_type,value:_value})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 初始化默认选中
      var _searchQueryObj = JSON.parse(window.sessionStorage.getItem('searchQuery'))
      if(_searchQueryObj){
        // console.log(_searchQueryObj.orderBy)
        this.activeName=['choice','choice1','choice2','choice3'][parseInt(_searchQueryObj.orderBy)]
        this.initLight = _searchQueryObj.OrderT==0?false:true
        this.startPrice=_searchQueryObj.startPrice==0?'':_searchQueryObj.startPrice//价格区间
        this.endPrice=_searchQueryObj.endPrice==0?'':_searchQueryObj.endPrice//价格区间
      }else{
        this.activeName='choice'
      }
    })
  },
  watch:{
    'activeName'(n,o){
      this.activeNamemo = n
    },
    'startPrice'(n,o){
      clearTimeout(this.t);
      this.t = setTimeout(()=>{
        if(n==''){
          n=0;
        }
        this.$emit('exportStr',n)
      },1000)
    },
    'endPrice'(n,o){
      clearTimeout(this.t1);
      this.t1 = setTimeout(()=>{
        if(n==''){
          n=0;
        }
        this.$emit('exportEnd',n)
      },1000)
    },
    '$store.state.searchClick'(n,o){
      this.activeName="choice"
      this.activeNamemo='choice'
      this.initLight=true
      this.startPrice=''//价格区间
      this.endPrice=''//价格区间
    },
  }
}
</script>
<style  lang="scss" scoped>
.searchparam {
  background: #fff;height: 40px;border-radius: 5px;overflow: hidden;
  /deep/.el-tabs {
    background: #fff;float:left;
    .el-tabs__active-bar {
      background: #fe7c80;
    }
    .el-tabs__header {
      margin-bottom:0px;
    }
    .el-tabs__item {
      min-width: 135px !important;
       text-align: center;
      .arrows {
        vertical-align: -1px;fill: #797979;width: 12px;height: 12px;
      }
      .light {
        fill: #fe7c80;
      }
    }
    .is-active {
      color:#fe7c80;
    }
    .el-tabs__item:hover {
      color:#fe7c80;
    }
    .el-tab-pane {
      .el-tabs__active-bar {
        top:auto !important;height: 3px !important;width: 56px !important;left:calc((135px - 56px)/2);border-radius: 0px !important;bottom:0px!important;
      }
    }
    .el-tabs__content {
      display: none;
    }
    .el-tabs__nav-wrap::after  {display: none;}
  }
  .priceNumber {
    font-size: 14px;height: 40px;float:left;line-height: 40px;margin-left:20px;
    p {
      float:right;margin-right: 5px;
      span {
        font-size:12px;margin-right: -10px;position: relative;z-index: 9;
      }
      input {
        border:1px solid #e6e6e6;background: #f2f2f2;border-radius: 20px;height: 24px;width: 50px;padding-left:15px;font-size: 12px;color:#333;
      }
      &:nth-child(2){
        margin-right: 10px;
      }
    }
  }
}
</style>
