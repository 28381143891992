import { render, staticRenderFns } from "./likes1.vue?vue&type=template&id=2c093588&scoped=true&"
import script from "./likes1.vue?vue&type=script&lang=js&"
export * from "./likes1.vue?vue&type=script&lang=js&"
import style0 from "./likes1.vue?vue&type=style&index=0&id=2c093588&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c093588",
  null
  
)

export default component.exports